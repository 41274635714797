import {useState,useEffect} from "react"
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'


function PasswordProtect({children,password='ams2020'}) {  
  const [psk,setPsk] = useState('')
  useEffect(()=>{
    if (psk===password) {      
      localStorage.setItem('userSession',Date.now() +  3600*1000)
    } else {      
      const timeout = localStorage.getItem('userSession')
      if (timeout && timeout > Date.now()) {        
        setPsk(password)
      }
    }
  },[psk,password])



  return (<>
      { psk !==password ? 
    <Box sx={{m:'30vh auto',maxWidth:'960px',textAlign:'center'}}>    
  <Box sx={{m:'auto',maxWidth:'100vw'}}>
    <TextField
      label="Enter Password"
      value={psk}
      type='password'
      onChange={(e)=>setPsk(e.target.value)}
    />
  </Box>     
    </Box> :
    <>{children}</>
  }</>)
}

export default PasswordProtect