import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import {ThemeProvider, createTheme} from '@mui/material/styles'



// import pages
import Index from './routes/index'
// import VideoPage from './routes/VideoPage'
import PageNotFound from './routes/PageNotFound'
import GifPage  from './routes/SlidePage'

const theme = createTheme({
  spacing:8,
  components: {
    MuiButton:{
      defaultProps:{
        variant:'contained',        
      }
    }
  }
})


function App() {
  return (<BrowserRouter>
  <ThemeProvider theme={theme}>
    <Routes>
      <Route path="/" element={<Index/>} />
      {/* <Route path="/video" element={<VideoPage/>}/> */}
      <Route path="/swab" element={<GifPage testType={'swab'}/>}/>
      <Route path="/saliva" element={<GifPage testType={'saliva'}/>} />
      <Route path='*' element={<PageNotFound/>} />
    </Routes>
    </ThemeProvider>
  </BrowserRouter>);
}

export default App;
