import gif0  from "../assets/saliva/0_Welcome.gif"
import gif1  from "../assets/saliva/1_PlugInReader.gif"
import gif2  from "../assets/saliva/2a_DepositSaliva.gif"
import gif3  from "../assets/saliva/2b_CheckFillLevel.gif"
import gif4  from "../assets/saliva/3a_ApplyCap.gif"
import gif5  from "../assets/saliva/3b_CheckCap.gif"
import gif6  from "../assets/saliva/4_Shake.gif"
import gif7  from "../assets/saliva/5a_OpenSensor.gif"
import gif8  from "../assets/saliva/5b_RemoveProtector.gif"
import gif9  from "../assets/saliva/5c_FillSensor.gif"
import gif11 from "../assets/saliva/6a_CheckReaderReady.gif"
import gif12 from "../assets/saliva/6b_StartTest.gif"
import gif13 from "../assets/saliva/7_ReadResults.gif"
import gif14 from "../assets/saliva/8_DiscardSensor.gif"
import gif15 from "../assets/saliva/9_Restart.gif"


import sgif0  from "../assets/swab/0_Welcome.gif"
import sgif1  from "../assets/swab/1_PlugInReader.gif"
import sgif2  from "../assets/swab/2a_SwabNose.gif"
import sgif3  from "../assets/swab/2b_OtherNostril.gif"
import sgif4  from "../assets/swab/2c_BreakSwab.gif"
import sgif5  from "../assets/swab/3a_ApplyCap.gif"
import sgif6  from "../assets/swab/3b_CheckCap.gif"
import sgif7  from "../assets/swab/4_Shake.gif"
import sgif8  from "../assets/swab/5a_OpenSensor.gif"
import sgif9  from "../assets/swab/5b_RemoveProtector.gif"
import sgif10 from "../assets/swab/5c_FillSensor.gif"
import sgif12 from "../assets/swab/6a_CheckReaderReady.gif"
import sgif13 from "../assets/swab/6b_StartTest.gif"
import sgif14 from "../assets/swab/7_ReadResults.gif"
import sgif15 from "../assets/swab/8_DiscardSensor.gif"
import sgif16 from "../assets/swab/9_Restart.gif"











import {useState} from "react"
import Helmet from "react-helmet"
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { styled } from "@mui/material/styles"





const ColorButton = styled(Button)({
  boxShadow: '2px 2px 3px #888',
  backgroundColor: '#343434',  
  textTransform: 'none',  
  padding: '15px 10px',  
  borderRadius:20,
  
  "@media all and (hover: hover) and (pointer: fine) ": {    
    '&:hover': {        
      backgroundColor: '#545454',  
      boxShadow: '2px 2px 3px #888',
    }    
  },
  "@media not all and (hover: hover) and (pointer: fine) ": {    
    '&:hover': {        
      backgroundColor: '#343434',  
      boxShadow: '2px 2px 3px #888',
    }
  }, 
  '&:active': {
    boxShadow: '1px 1px 15px #888',
    marginTop:'1px',    
  },
});

const gifs = [
  gif0,
  gif1,
  gif2,
  gif3,
  gif4,
  gif5,
  gif6,
  gif7,
  gif8,
  gif9,  
  gif11,
  gif12,
  gif13,
  gif14,
  gif15
]

const sgifs = [
  sgif0,
  sgif1,
  sgif2,
  sgif3,
  sgif4,
  sgif5,
  sgif6,
  sgif7,
  sgif8,
  sgif9,
  sgif10,  
  sgif12,
  sgif13,
  sgif14,
  sgif15,
  sgif16
]

const Btn = ({onClick,children}) => <Box> 
<ColorButton sx={{fontSize:{xs:'1.6rem',sm:'1.8rem',md:'2rem'},minWidth:{xs:'9rem',sm:'10rem',md:'11rem'}}} onClick={onClick} disableRipple>{children}</ColorButton>
</Box> 


function GifPage({testType}) {
  const userGuideGif = testType==='saliva' ? gifs : sgifs

  const [p,setP] = useState(0)
  
  const handleNext = (e) => {    
    if (p===userGuideGif.length-1) {
      setP(0)
    } else {
      setP(p+1)
    }  
  }
  const handlePrev = (e) => {
    setP(p-1)  
  }
  
  return (
     <Box sx={{m:'15px auto',maxWidth:'960px',textAlign:'center'}}>
    <Helmet>
      <title>User Guide</title>
    </Helmet>
  <Box sx={{m:'auto',maxWidth:'100vw'}}>
    <Box sx={{overflow:'hidden',
      margin:'auto',
      width:'min(100vw, 450px)',
      height:'calc(min(100vw, 450px) * 1.395)'}}>
      {
        userGuideGif.map((gif,i)=><img key={i} src={gif} alt="" style={{
          maxWidth:'100%',
          display:p===i?'block':'none',
          }}/>)
      }
      </Box> 
      {p===0 && <Btn onClick={handleNext}>Yes! Let's Get Started!</Btn>
       }
      {p>0 && <Box
        display="flex"
        justifyContent="space-around"
        sx={{margin:'auto',maxWidth:'min(100vw, 450px)', p:'0px 10px'}}
      >
        <Btn onClick={handlePrev}>Previous</Btn>
        <Btn onClick={handleNext}>{ p<userGuideGif.length-1 ? "Next" : "Start Over"}</Btn>
        </Box>}    
  </Box>     
    </Box>   
  )
}


export default GifPage