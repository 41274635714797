import {Link as RouterLink} from 'react-router-dom'

import Link from '@mui/material/Link'
import Helmet from 'react-helmet'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import PasswordProtect from "../components/PasswordProtect"
export default function Index () {
    return <>
        <Helmet>
            <title>ACE</title>
        </Helmet>
        <PasswordProtect>        
        <Box sx={{padding:"4rem 0px",m:'auto',width:'fit-content','& a':{m:'1rem'}}}>
        <Link component={RouterLink} to='/saliva'>
            <Typography variant='h4'>ACE Saliva Test QRI</Typography>
        </Link>
        <Link component={RouterLink} to='/swab'>
        <Typography variant='h4'>ACE Swab Test QRI</Typography>
        </Link>

        <Link target='_blank' href='https://youtu.be/1LfzjBOWuy8'>
        <Typography variant='h4'> Saliva Demo</Typography>
        </Link>
        <Link target='_blank' href='https://youtu.be/9MqHK1niOnk'>
        <Typography variant='h4'> Swab Demo</Typography>
        </Link>
        
        </Box>
        
        </PasswordProtect>
    </>
}

