import Button from '@mui/material/Button'
import {Link} from 'react-router-dom'
import HomeIcon from '@mui/icons-material/HomeRounded'
import Typography from '@mui/material/Typography'
import {Helmet} from 'react-helmet'
function pageNotFound({hideHome}) {
    return (
        <div className='container'>
            <Helmet>
                <title>Page Not Found</title>
            </Helmet>
        <div className='info-content' >
      
            <Typography variant='button' style={{fontSize:130,letterSpacing:15,fontWeight:600}}>4
            <Typography style={{display:'inline',fontSize:130,letterSpacing:15,fontWeight:600}} color='error'>0</Typography>
            4</Typography>
            <Typography variant='button' style={{fontSize:24}}>The page you requested could not be found.</Typography>
            <Typography variant='body1'>If you think this is an error, please contact us.</Typography>
            <div style={{marginTop:25}}>
            {
            !hideHome &&
            <Button 
            component={Link}
            to={'/'}
            color='primary'
            style={{fontSize:32}}
            startIcon={<HomeIcon/>}
            >Home</Button>
            }
            </div>
        </div>  </div>
    )
}

export default pageNotFound
